<template>
  <card :page-title="pageTitle" :page-icon="pageIcon">
    <g-overlay :is-relay="isOverlay" />
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-form ref="paypalForm" v-model="validLogin" lazy-validation v-on:submit.prevent>
              <v-text-field
                  outlined
                  dense
                  color="deep-purple accent-4"
                  type="text"
                  prepend-inner-icon="mdi-pencil"
                  v-model="paypal.payerId"
                  :rules="requiredFieldRule"
                  label="Payer ID"
              />
              <v-text-field
                  outlined
                  dense
                  color="deep-purple accent-4"
                  type="text"
                  prepend-inner-icon="mdi-pencil"
                  v-model="paypal.payId"
                  :rules="requiredFieldRule"
                  label="Payment ID"
              />
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-outlined-button
                text="Check Payment"
                color="primary"
                icon="mdi-checkerboard"
                @onButtonClick="paypalHandler"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="text-md-left">
          <v-card-title>
            Transaction Details
          </v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Payment ID: {{payment.cart}}</v-list-item-title>
                <v-list-item-subtitle>State: {{payment.state}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="orange lighten-2"
                text
            >
              Payer
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                @click="payerShow = !payerShow"
            >
              <v-icon>{{ payerShow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="payerShow">
              <v-divider></v-divider>
              <v-card-text>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Status: {{payment.status}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Payer Info</v-list-item-title>
                    <v-list-item-subtitle>Status: {{payment.payer ? payment.payer.status : ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Email: {{payment.payer && payment.payer.payer_info? payment.payer.payer_info.email : ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>First Name: {{payment.payer && payment.payer.payer_info ? payment.payer.payer_info.first_name: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Last Name: {{payment.payer && payment.payer.payer_info ? payment.payer.payer_info.last_name: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Payer ID: {{payment.payer && payment.payer.payer_info ? payment.payer.payer_info.payer_id: ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Shipping address</v-list-item-title>
                    <v-list-item-subtitle>Recipient Name: {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.recipient_name: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Line 1: {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.line1: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>City: {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.city: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>State: {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.state: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Postal: {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.postal_code: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Country:  {{payment.payer && payment.payer.payer_info &&  payment.payer.payer_info.shipping_address? payment.payer.payer_info.shipping_address.country_code: ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </div>
          </v-expand-transition>
          <v-card-actions>
            <v-btn
                color="orange lighten-2"
                text
            >
              Transaction
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                @click="transactionShow = !transactionShow"
            >
              <v-icon>{{ transactionShow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="transactionShow">
              <v-divider></v-divider>

              <v-card-text>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Amount</v-list-item-title>
                    <v-list-item-subtitle>Total: {{payment.transactions ? payment.transactions[0].amount.total: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Currency: {{payment.transactions ? payment.transactions[0].amount.currency: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Subtotal: {{payment.transactions ? payment.transactions[0].amount.details.subtotal: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Shipping: {{payment.transactions ? payment.transactions[0].amount.details.shipping: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Insurance: {{payment.transactions ? payment.transactions[0].amount.details.insurance: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Handling Fee: {{payment.transactions ? payment.transactions[0].amount.details.handling_fee: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Shipping Discount: {{payment.transactions ? payment.transactions[0].amount.details.shipping_discount: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Discount: {{payment.transactions ? payment.transactions[0].amount.details.discount: ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Payee</v-list-item-title>
                    <v-list-item-subtitle>Payee: {{payment.transactions ? payment.transactions[0].payee.email: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Description: {{payment.transactions ? payment.transactions[0].description: ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Resources</v-list-item-title>
                    <v-list-item-subtitle>State: {{payment.transactions && payment.transactions[0].related_resources? payment.transactions[0].related_resources[0].sale.state: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Total: {{payment.transactions && payment.transactions[0].related_resources? payment.transactions[0].related_resources[0].sale.amount.total: ''}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Currency: {{payment.transactions && payment.transactions[0].related_resources? payment.transactions[0].related_resources[0].sale.amount.currency: ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>Payment Mode</v-list-item-title>
                    <v-list-item-subtitle>Payment Mode: {{payment.transactions && payment.transactions[0].related_resources? getPaymentMode(payment.transactions[0].related_resources[0].sale.payment_mode): ''}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </card>
</template>

<script>
import Card from "../components/Card";
import Validation from "../components/mixins/Validation";
import BaseOutlinedButton from "../components/buttons/BaseOutlinedButton";
import Paypal from "../services/Paypal";
import GOverlay from "../components/GOverlay";
import DateHelper from "../components/mixins/DateHelper";

export default {
  name: "Paypal",
  components: {GOverlay, BaseOutlinedButton, Card},
  mixins: [Validation, DateHelper],
  data() {
    return {
      payerShow: false,
      transactionShow: false,
      validLogin: true,
      isOverlay: false,
      pageTitle: "Paypal Payment Checker",
      pageIcon: "mdi-checkerboard",
      paypal: {
        payId: '',
        payerId: '',
      },
      payment: {},
    }
  },
  methods: {
   async paypalHandler() {
     this.payment = {};
     if (!this.$refs.paypalForm.validate()) return;
     this.isOverlay = true;
     try {
       const {data} = await Paypal.checkPaypalPayment({...this.paypal});

       if(!data.data) {
         this.$store.commit('SET_SNACKBAR', {
           text: data.message,
           type: "error",
         })
       } else {
         this.payment = data.data;
       }
       this.isOverlay = false;
     } catch (error) {
       this.isOverlay = false;
     }
    },
    getPaymentMode(text) {
     return text.replaceAll("_", " ");
    }
  }
}
</script>

<style scoped>

</style>